import React from "react";
import Obfuscate from "react-obfuscate";

const ContactDetails: React.FC = () => (
  <div className="e-contact-details">
    <Obfuscate email="hellolondon@thebeinggroup.com" />
    <Obfuscate tel="+44 7341 93 97 98" />
  </div>
);

export default ContactDetails;
