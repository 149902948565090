import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { ImageType } from "@/types";

const ImageElement: React.FC<ImageType> = ({ asset, alt }) => (
  <>
    {asset === "placeholder" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/placeholder.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        placeholder="blurred"
      />
    )}
    {asset === "studio" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/studio.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
      />
    )}
    {asset === "multilit" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/multilit.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
      />
    )}
    {asset === "24h" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/24h.svg"
        alt={alt || ""}
        // formats={["auto", "webp", "avif"]}
        formats={["auto", "webp", "avif"]}
        layout={"fullWidth"}
        height={300}

      />
    )}
    {asset === "best-friend" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/best-friend.svg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        layout={"fullWidth"}
        height={300}
      />
    )}
    {asset === "sleep" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/sleep.svg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        layout={"fullWidth"}
        height={300}

      />
    )}
    {asset === "time-zone" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/time-zone.svg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        layout={"fullWidth"}
        height={300}

      />
    )}
  </>
);

export default ImageElement;
