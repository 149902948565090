import React from "react";
import Layout from "@/layout";
import SeoComponent from "@/modules/seo";
import Services from "@/modules/services";
import Showcase from "@/modules/showcase";
import Banner from "@/modules/banner";
import Hero from "@/modules/hero";
import Video from "@/modules/video";
import Collaboration from "@/modules/collaboration";
import WorkFlow from "@/modules/work-flow";
import Wrapper from "@/layout/Wrapper";

type IndexType = {
  pageContext: any;
};

const Index: React.FC<IndexType> = ({ pageContext }) => {
  const {
    title,
    description,
    keywords,
    accentColour,
    hero,
    services,
    banner,
    video,
    workflow,
    showcase,
    footer,
  } = pageContext;

  return (
<React.StrictMode>
  <Layout colour={accentColour} footer={footer}
          data-scroll-section
  >
      <SeoComponent
        title={title}
        description={description}
        keywords={keywords}
      />
      <Hero
        lineOne={hero.lineOne}
        lineTwo={hero.lineTwo}
        lineThree={hero.lineThree}
        cta={hero.cta}
      />
      <Services
        sectionHeading={services.sectionHeading}
        intro={services.intro}
        heading={services.heading}
      />
      <Video videoItems={video} />
      <Banner text={banner} />
      <Collaboration
          sectionHeading={services.collabHeading}
          services={services.services}
          services0 = {services.services0}
          services1 = {services.services1}
          services2 = {services.services2}
          services3 = {services.services3}
      />
      <WorkFlow
        header={workflow.header}
        text={workflow.text}
      />

      <Showcase showcaseItems={showcase.items} />
    </Layout>
</React.StrictMode>

);
};

export default Index;
