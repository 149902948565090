import React from "react";

type IntroProps = {
  sectionHeading?: string;
};

const Intro: React.FC<IntroProps> = ({ sectionHeading}) => (
  <div
    className="e-intro"
    data-scroll
    data-scroll-speed="1.1"
    data-scroll-target="#services-marker"
  >
    {sectionHeading && (
      <p className="e-intro__heading heading-service">{sectionHeading}</p>
    )}
  </div>
);

export default Intro;
