import React from "react";
import ContactDetails from "@/elements/contact-details";
import ContactForm from "@/components/contact-form";

type BannerType = {
  text: string;
};

const Banner: React.FC<BannerType> = ({ text }) => (
  <section
    className="m-banner__wrapper light;
}"
    data-scroll-section
    id="work-form-marker"
  >

    <div className="m-banner container">
      <div className="m-banner__contact">
        <p className="m-banner__contact-copy">{text}</p>
        <div className="m-banner__contact-details" id="banner-form">
          <ContactDetails />
        </div>
      </div>
      <div className="m-banner__form-wrapper">
        <ContactForm />
      </div>
    </div>
  </section>
);

export default Banner;
