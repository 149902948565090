import React from "react";

export type ServiceItem = {
  name: string;
};

type ServiceListTypes = {
  heading?: string;
};

const WelcomeIntro: React.FC<ServiceListTypes> = ({ heading}) => (
  <div
    className="e-intro c-service-list"
    data-scroll
    data-scroll-speed="1.2"
    data-scroll-target="#services-marker"
  >
    <>
      {heading && (
        <h2
          className="c-service-list__heading heading-service-1"
          data-scroll
          data-scroll-target="#services-marker"
          data-scroll-offset="40%"
        >
          {heading}
        </h2>
      )}
    </>
  </div>
);

export default WelcomeIntro;
