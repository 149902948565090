import React from "react";
import Intro from "@/elements/intro";
// import ServiceList, { ServiceItem } from "@/components/service-list";
import WelcomeIntro from "@/components/welcome-intro";

type ServicesType = {
  sectionHeading?: string;
  intro: string;
  heading: string;
};
const Services: React.FC<ServicesType> = ({
  sectionHeading,
  intro,
}) => {
  return (
    <section
      className="c-services__wrapper light"
      data-scroll-section
      id="services-marker"
    >
      <div
        className="c-services container underline"
        data-scroll
        data-scroll-offset="40%"
        data-scroll-target="#services-marker"
      >
        <Intro sectionHeading={sectionHeading}/>
        <WelcomeIntro heading={intro}/>
      </div>
    </section>
  );
};

export default Services;
