import React from "react";
import IntroCollab from "@/elements/intro-collab";
import ServiceCollab, {ColabItem, ColabItem0, ColabItem1, ColabItem2, ColabItem3} from "@/components/service-colab";
// import Line from "@/img/line.svg";

type ServicesType = {
  sectionHeading?: string;
  services: ColabItem[];
  services0: ColabItem0[];
  services1: ColabItem1[];
  services2: ColabItem2[];
  services3: ColabItem3[];
};

const Collaboration: React.FC<ServicesType> = ({
                                            sectionHeading,
                                            services,
  services0,
  services1,services2,services3
                                          }) => {
  return (
    <section
      className="m-collab__container l-grey"
      data-scroll-section
      id="collab-marker"
    >
      <div
        className="c-collab-item__wrapper"
        data-scroll
        // data-scroll-offset="75%"
        data-scroll-target="#services-marker"
      >
        <IntroCollab sectionHeading={sectionHeading}/>

        <ServiceCollab services={services}
                       services0 = {services0}
                       services1 = {services1}
                       services2 = {services2}
                       services3 = {services3}/>
      </div>

    </section>
  );
};

export default Collaboration;
