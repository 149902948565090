import React from "react";

export type ColabItem = {
  name0: string;
  name1: string;
  name2: string;
  name3: string;
};
export type ColabItem0 = {
  name0: string;
  name1: string;
};
export type ColabItem1 = {
  name1: string;
  name0: string;
};
export type ColabItem2 = {
  name2: string;
  name0: string;
};
export type ColabItem3 = {
  name3: string;
  name0: string;
};

type ServiceListTypes = {
  services: ColabItem[];
  services0: ColabItem0[];
  services1: ColabItem1[];
  services2: ColabItem2[];
  services3: ColabItem3[];
};

const ServiceCollab: React.FC<ServiceListTypes> = ({services,services0,services1,services2,services3 }) => (
  <div
    className=""
    // id={`showcase-item-marker-${index}`}
    // ref={ref}
  >
    <div className="c-collab-item colab-item-text container">


    <div className="c-collab-item__1">
        {services0.map((service0, i) => (
          <div key={i}>
            {service0.name0}
          </div>
        ))}
    </div>
    <div className="c-collab-item__1">
          {services1.map((service1, i) => (
            <div key={i}>
              {service1.name1}
            </div>
          ))}
    </div>
      <div className="c-collab-item__1">
        {services2.map((service2, i) => (
          <div key={i}>
            {service2.name2}
          </div>
        ))}
      </div>
      <div className="c-collab-item__1">
        {services3.map((service3, i) => (
          <div key={i}>
            {service3.name3}
          </div>
        ))}
      </div>
    </div>
    </div>
);

export default ServiceCollab;

