import React from "react";
import  {VideoItemType} from "@/components/video-item";

type ShowcaseType = {
  videoItems: VideoItemType;
};
const Video: React.FC<ShowcaseType> = ({ }) => {
  return (
    <section
      className=" single-vid-padding"
      data-scroll-section
      id="video-intro-marker"
    >

      <script src="https://player.vimeo.com/api/player.js"></script>
      <div
        className="c-showcase-item__wrapper"
      >
        <div className="c-showcase-item container single-vid__wrapper">
          <div
            className=" c-single-vid"
          >

            {/*<script src="https://player.vimeo.com/api/player.js"></script>*/}
            <div className="c-showcase-item__media" id="single-vid">
              <div className="vimeo-overlay"></div>

              <div className={"vimeo-single-vid"}>
                <iframe
                  // src="https://player.vimeo.com/video/528635705?h=7bcb1c921d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  // src="https://player.vimeo.com/video/525340549?h=cffa3c0643&amp;badge=0&amp;autopause=0&autoplay=1&amp;player_id=0&amp;app_id=58479"
                  src="https://player.vimeo.com/video/781716883?h=daae232c33&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  // frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                  // className={"iframe-single-vid"} title="BEING_Banner_2021" id={"allow-pointer"}
                  frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                  className={"iframe-single-vid"}
                  title="The Being Group - The Story of our Brand"
                  ></iframe>
              </div>
            </div>


          </div>
        </div>
      </div>

    </section>
  );
};

export default Video;
